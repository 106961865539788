div.banner {
    width: 100px;
    height: 100px;
    position: relative;
    animation-name: banner;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    white-space: nowrap;
}

div.mobileBanner {
    width: 100px;
    height: 100%;
    position: relative;
    animation-name: banner;
    animation-duration: 27s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    white-space: nowrap;
}

div.banner:hover {
    animation-play-state: paused;
}
  
@keyframes banner {
    0%   {right:0px;}
    100% {right:264.2em;}
}