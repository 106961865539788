button {
    width: 100%;
    background-color: #03305f;
    color: white;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 2px;
    cursor: pointer;
    border: 1px solid #fff;
}

div.show {
    padding: 3px;
    padding-left: 5px;
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
    text-align: left;
    font-size: 15px;
}

button.city {
    animation: city 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0 0 forwards 0;
}

@keyframes city {
    0%   {right:0px;}
    100% {right:200em;}
}